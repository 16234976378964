import React, { useEffect } from 'react';
import { AppData } from './PageContext';
import { getPermission } from '@/modules/accounts/viewmodels/getPermission';
import { authenticate, getCustomTokenFromSSOServer } from '../auth';
import { getUserByFirebaseId } from '@/modules/users/viewmodels/findFirebaseId';
import { UsersEntity } from '@/domain/entities/users';

interface Data {
  permission: string[];
  userData: UsersEntity;
}

export const AppProvider = (props: any) => {
  const { actionGetPermission, permissionData } = getPermission();
  const { userData, actionGetUserByFirebaseId } = getUserByFirebaseId();

  const findUser = async () => {
    const customToken = await getCustomTokenFromSSOServer();
    const rs = await authenticate(customToken);
    const { userId } = rs;
    if (userId) {
      actionGetUserByFirebaseId(userId);
    }
  };

  useEffect(() => {
    actionGetPermission();
    findUser();
  }, []);

  const data: Data = {
    permission: permissionData,
    userData,
  };

  return <AppData {...data}>{props.children}</AppData>;
};
