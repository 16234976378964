import { gql } from '@apollo/client';
import { CustomerNeedData } from './customer-need-data';

export const GET_CUSTOMER_NEEDS = gql`
  query findAll {
    CustomerNeed_getAll {
      ${CustomerNeedData}
    }
  }
`;

export const UPDATE_CUSTOMER_NEED = gql`
  mutation CustomerNeed_update($payload: UpdateCustomerNeedInput!) {
    CustomerNeed_update(updateCustomerNeedInput: $payload) {
      ${CustomerNeedData}
    }
  }
`;
