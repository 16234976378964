import { defaultsDeep } from 'lodash';
import { overrideConfig } from './override.config';

export interface Config {
  title: string;
  footer: {
    text: string;
  };
  firebase: {
    apiKey: string;
    authDomain: string;
    databaseURL: string;
    projectId: string;
    storageBucket: string;
    messagingSenderId: string;
    appId: string;
    measurementId: string;
  };

  base: {
    webUrl: string;
    apiUrl: string;
    baseUrl: string;
  };
  powerbiUrl: {
    link: string;
    group: string;
    report: string;
  };
}

const defaultConfig: Config = {
  title: 'MindX Technology School',
  footer: {
    text: 'MindX Technology School 2022',
  },
  firebase: {
    apiKey: 'AIzaSyB-x8gFuVKzbIoB1aYKbG1jrvm8mbZUmkQ',
    authDomain: 'mindx-edu-dev.firebaseapp.com',
    databaseURL: 'https://mindx-edu-dev.firebaseio.com',
    projectId: 'mindx-edu-dev',
    storageBucket: 'mindx-edu-dev.appspot.com',
    messagingSenderId: '592744290445',
    appId: '1:592744290445:web:aa82213d363f9b59c5eac4',
    measurementId: 'G-QPEELWB8Q4',
  },
  base: {
    webUrl: 'https://base-dev.mindx.edu.vn',
    apiUrl: 'http://localhost:8000/reports/graphql',
    baseUrl: 'https://base-api-dev.mindx.edu.vn',
  },

  powerbiUrl: {
    link: 'app.powerbi.com',
    group: 'groups',
    report: 'report',
  },
};

export const config = defaultsDeep(overrideConfig, defaultConfig) as Config;
