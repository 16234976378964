import React, { ReactElement, useEffect, useState } from 'react';
import {
  AppstoreAddOutlined,
  IdcardOutlined,
  LeftOutlined,
  RightOutlined,
  SettingOutlined,
  SignalFilled,
} from '@ant-design/icons';
import type { MenuProps } from '../Menu';
import { Menu } from '../Menu';
import { Divider, Typography, Layout } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { menuIcon } from '../helps/ListOptionsIcon';
import { useSSOAuthentication } from '@/core/hooks';
import useViewModel from '../../modules/menu-items/viewmodels/list';
import { Authorize } from '@/core';
import { PERMISSIONS } from '@/core/constants/permission.constant';
import { get } from 'lodash';
type Props = {
  collapsed: boolean;
  setCollapsed: (collapsed: boolean) => void;
  setTitle: React.Dispatch<React.SetStateAction<string>>;
};

type MenuItem = Required<MenuProps>['items'][number];

const getItem = (
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[] | undefined | null,
  disabled?: boolean,
): MenuItem => {
  return {
    key,
    icon,
    children,
    label,
    disabled,
  } as MenuItem;
};
const SidebarScreen = (props: Props) => {
  const { collapsed, setCollapsed, setTitle } = props;
  const { Sider } = Layout;
  const navigate = useNavigate();
  const { loading, error, pageTree, actionGetTree, pageData, actionGetAll } = useViewModel();
  const { isAllow: isAllowMenuConfigView } = useSSOAuthentication(
    true,
    PERMISSIONS.MENU_ITEMS.VIEW,
  );
  const { isAllow: isAllowDashboardConfigView } = useSSOAuthentication(
    true,
    PERMISSIONS.DASHBOARDS.VIEW,
  );
  const { dataTree } = pageTree;
  const { dataList } = pageData;
  useEffect(() => {
    actionGetTree();
    actionGetAll({
      isActive: true,
      limit: 100,
    });
  }, []);
  const findIcons = (value: string) => {
    const Icons = menuIcon.find((item) => item.value === value);
    if (Icons) {
      // todo
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return Icons?.label;
    }
    return '';
  };
  const getMenuItem = (item: any) =>
    getItem(get(item, 'name'), get(item, 'dashboardConfig.url'), findIcons(get(item, 'icon')));

  const getSubMenus = (subMenu: any) => {
    let render = false;
    if (subMenu && subMenu.children) {
      for (const item of subMenu.children) {
        render = true;
        break;
      }
      if (render) {
        return getItem(
          subMenu.name,
          subMenu.dashboardConfig.url,
          findIcons(subMenu.icon),
          subMenu.children?.map((item: any) => {
            return item?.children && item?.children.length ? getSubMenus(item) : getMenuItem(item);
          }),
        );
      } else {
        return getMenuItem(subMenu);
      }
    }
    return getMenuItem(subMenu);
  };
  const titleMenu: MenuItem[] = dataList ? dataList.map((item) => getMenuItem(item)) : [];
  const menu: MenuItem[] = dataTree ? dataTree.map((item) => getSubMenus(item)) : [];
  const items: MenuItem[] = [];
  const getMenuItemConfig = getItem(
    'Menu Item',
    '/menu-items',
    <IdcardOutlined style={collapsed ? { fontSize: '24px' } : { fontSize: '18px' }} />,
  );
  const getDashboardConfig = getItem(
    'Dashboard Management',
    '/dashboard-management',
    <AppstoreAddOutlined style={collapsed ? { fontSize: '24px' } : { fontSize: '18px' }} />,
  );
  const itemsLast: MenuItem[] = [
    getItem(
      'Setting',
      '/setting',
      <SettingOutlined style={collapsed ? { fontSize: '24px' } : { fontSize: '18px' }} />,
      [
        isAllowMenuConfigView ? getMenuItemConfig : null,
        isAllowDashboardConfigView ? getDashboardConfig : null,
      ],
    ),
  ];
  const allItemTree = items.concat(menu, itemsLast);
  const allItemList = items.concat(titleMenu);

  return (
    <Sider
      collapsible
      collapsed={collapsed}
      onCollapse={(value) => setCollapsed(value)}
      style={collapsed ? { background: '#333' } : { background: '#000' }}
    >
      <Link to='/' className='text-white flex justify-center items-center h-[66px] gap-[10px]'>
        {collapsed ? (
          <SignalFilled
            className='w-[48px] h-[48px]'
            style={{ fontSize: '33px', lineHeight: '33px' }}
          />
        ) : (
          <>
            <SignalFilled />
            <Typography.Title className='text-white mb-0' level={4}>
              MindX Report
            </Typography.Title>
          </>
        )}
      </Link>
      <Menu
        onClick={(onClickParams) => {
          const { key: path } = onClickParams;
          navigate(path);
        }}
        onSelect={(onSelectParams) => {
          const labelSelect = allItemList.find((i) => i?.key === onSelectParams.key);
          // todo
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          setTitle(labelSelect?.label);
        }}
        theme='dark'
        mode='inline'
        items={allItemTree}
      />
    </Sider>
  );
};

export const Sidebar = Authorize<Props>(SidebarScreen, PERMISSIONS.APP.VIEW);
